
@import '../../styles/common';
@import '~@andes/button/index';
@import '~@andes/dropdown/index';
@import '~@andes/list/index';
@import '~@andes/form/index';


$site-link-color: rgba(255, 255, 255, 0.5);
.crossite {
  height: 100%;
  width: 100%;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
}
main{
  height: 50vh;
  margin: 0;
}
.contenedor-ml {
    background: linear-gradient(to bottom, $andes-brand-main 46%, white 36%);
    height: 50vh;
    margin: 0;
}

.contenedor-mp {
  --andes-color-fill-brand: var(--andes-color-blue-500);
  background: linear-gradient(to bottom, $andes-brand-main 46%, white 40%);
  height: 50vh;
  margin: 0;
}

html {
  text-align: center;
}

body {
  font-family: $font-family-primary;
}

a {
  color: $andes-blue-800;
  :visited {
    color: $andes-blue-800;
  }
}

.mp-logo {
  width: 50%;
  padding: 9%;

}
.ml-logo{
  width: 26%;
  padding: 5%;
}

.align-item {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

span {
  display: inline-block;
  margin-left: 5px;
}

.content {
  display: flex;
  flex-direction: column;
}


.list-sites {
  text-align: left;
  width: 100%;
}

.a-site-link {
  background-color: $site-link-color;
  border-bottom: 0;
  border-radius: 4px;
  display: inline-block;
  font-size: 1rem;
  padding: 2px 18px 2px 2px;
}

.container-dropdown-list{
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5%;
  width: 80vw;
}

.andes-floating-menu--show{
  height: 200px;
}
  
.andes-dropdown--open{
  overflow-y: auto;
  overflow-x: hidden;
}

.andes-dropdown > .andes-floating-menu {
  margin-top: 2rem;
}

.andes-dropdown.andes-dropdown--form .andes-form-control__label{
  font-weight: 600;
  font-size: 2rem;
  display: flex;
}

.li-site {
  padding: 1% 0%;
}

//**** Media para Desktop
@media screen and (min-width: 984px)  {
  .list-sites {
    width: 20%;
  }

  ul {
    columns: 1;
  }
  .ml-logo {
    width: 15%;
    padding: 2%;
  }
  .contenedor-ml{
    background: linear-gradient(to bottom, $andes-brand-main 115%, white 36%);
    height: 35vh;
  }
  .contenedor-mp {
    --andes-color-fill-brand: var(--andes-color-blue-500);
    background: linear-gradient(to bottom, $andes-brand-main 72%, white 36%);
    height: 42vh;
  }
  .mp-logo{
    width: 24%;
    padding: 4%;
  }
  .container-dropdown-list{
    padding: 1.5%;
    width: 30vw;
  }

}

