
@import './andes';
@import './variables';
@import './utils';


$box-shadow-custom-a: rgba(0, 0, 0, 0.14);
$box-shadow-custom-b: rgba(0, 0, 0, 0.05);

html {
  font-size: $app-font-size-base;
  font-family: $font-family-primary;
  color: $andes-gray-900;
}

.nav-header-menu-wrapper::after,
.nav-header::before {
  box-shadow: 0 2px 4px $box-shadow-custom-a, 0 1px 0 $box-shadow-custom-b;
}

.nav-footer {
  border-top-width: 0;
}

@media screen and (min-width: $breakpoint-desktop) {
  .nav-header-menu-wrapper::after,
  .nav-header::before {
    box-shadow: 0 0 0 0 $andes-white;
  }

  .nav-footer {
    border-top-width: 1px;
  }
}
