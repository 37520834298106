//base
$app-font-size-base: 10px;

// layout colors (temp)
//$brand-bg-color: #fff159 !default;
$brand-bg-color-mp: #00b1ea;
$brand-bg-color-ms: #d9545e;
$brand-bg-color-ti: #fff;
$brand-bg-color-pi: #fff;
$brand-bg-color-mt: #eee;

:root {
    --andes-color-blue-100: rgba(71, 154, 209, 0.1);
    --andes-color-blue-150: rgba(71, 154, 209, 0.15);
    --andes-color-blue-200: rgba(71, 154, 209, 0.2);
    --andes-color-blue-300: rgba(71, 154, 209, 0.3);
    --andes-color-blue-400: rgba(71, 154, 209, 0.4);
    --andes-color-blue-500: #009ee3;
    --andes-color-blue-600: #007eb5;
    --andes-color-blue-700: #005e88;
    --andes-color-blue-800: #004766;
}
